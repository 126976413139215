<template>
  <v-container id="productRequest" fluid tag="section">
    
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
};
</script>